// rem calc
@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

// transition
@mixin transition($elem, $time) {
  transition: $elem ease-in-out $time + s;
}

@mixin cubic-transition {
  transition: transform .45s cubic-bezier(.4,0,.2,1);
}

@mixin makeSpaces() {
  @each $item in $spaceArray {
    .space-#{$item} {
      margin-bottom: rem-calc($item);
    }
  }
}

@include makeSpaces()