.section {
  opacity: 1;

  @media(max-width : $breakpoint - 1) {
    opacity: 1 !important;
  }


  .no-touch & {
    @media(min-width : $breakpoint) {
      position: fixed;
    }
  }

  .col-md-4 {
    padding: 0;
  }

  // section image
  &_img {
    background-size: cover;
    background-position: top center;
    height: 50vh;
    top: 0;
    opacity: 1;
    width: 100%;
    transition: 1s;

    @media (max-width : $breakpoint - 1px) {
      transform: translate3d(0px, 0px, 0px) !important;
      opacity: 1 !important;
      width: 100% !important;
    }

    .no-touch & {
      @media (min-width : $breakpoint) {
        height: 100vh;
        position: fixed;
      }
    }
  }

  // section panel
  .panel {
    &.form-active {
      .btn--contact-form {
        display: none;
      }

      .form {
        transform: translateY(0px) translateZ(0);
        opacity: 1;
        transition: all .5s;
      }
    }
  }

  &--main {
    z-index: 11;

    .section_img {
      left: 0;
      transition: all 1s;
      z-index: 11;
    }
  }

  &--approach {
    z-index: 10;

    .section_img {
      transition: 1s;
      z-index: 10;
    }
  }

  &--gallery {
    z-index: 9;

    .panel {
      &_content {
        position: relative;

        .no-touch & {
          @media(min-width : $breakpoint) {
            //height: 100vh;
          }
        }
      }

      .no-touch & {
        @media(min-width : $breakpoint) {
          top: 0;
          right: 0;
          height: 100vh;
          width: 50vw;
          z-index: 11;
        }
      }
    }

    .section_img {
      position: relative;
      transition: 1s;
    }
  }

  &--team {
    background-color: black;

    .panel_box {

      .no-touch & {
        @media (min-width: $breakpoint) {
          //top: -100vh;
          transform: translate3d(0, -100vh, 0);
        }
      }
    }


    .section_img {
      .no-touch & {
        @media (min-width: $breakpoint) {
          //top: 100vh;
          transform: translate3d(0, 100vh, 0);
        }
      }
    }
  }

  &--service {
    background-color: black;
    height: 100%;
    width: 100%;

    div:not(.tile_text) {
      .no-touch & {
        @media (min-width : $breakpoint) {
          height: 100vh;
        }
      }
    }
  }

  &--contact {
    z-index: 6;

    @media (min-width : $breakpoint) {
      .no-touch & {
        opacity: 0;
      }
    }

    .panel {
      &--form {
        .no-touch & {
          @media(min-width: $breakpoint) {
            padding: 0 rem-calc(20);
          }
        }
      }

      .contact-item {
        display: flex;
        flex-flow: row nowrap;
        word-break: break-word;

        strong {
          margin-left:rem-calc(5);
        }

        &--hour {
          flex-flow: column;

          @media(min-width: $breakpoint) {
            flex-flow: row nowrap;
          }

          .contact-item_title {
            margin-left: rem-calc(49);

            @media(min-width: $breakpoint) {
              margin-left: 0;
            }
          }
        }

        &-wrap {
          margin-bottom: 3vh;
        }

        &_title {
          margin-left: rem-calc(5);
        }

        p {
          margin-bottom: 0;

          .no-touch & {
            @media(min-width : $wide-breakpoint) {

            }
          }
        }
      }

      &--left {
        .panel_content {
          .no-touch & {
            @media(min-width: $wide-breakpoint) {
              max-width: 100%;
            }
          }
        }

        .panel_box {
          .no-touch & {
            transform: translate3d(0, 0, 0);
          }
        }
      }

      @media(max-width : $breakpoint - 1) {
        display: block !important;
      }

      @media (min-width : $breakpoint) {
        .no-touch & {
        }

        &_content {
          .no-touch & {
            max-width: 80%;
            position: relative;
            left: 0;
            width: 100%;
            bottom: 0;
            padding: 0;
          }
        }
      }
    }

    .h1 {
      font-size: rem-calc(30);
      margin-bottom: 20px;

      @media(min-width : $wide-breakpoint) {
        .no-touch & {
          font-size: 2.1rem;
        }
      }
    }

    .form-group {
      @extend .space-20;
    }

    .panel--form {
      @media (min-width : $breakpoint) {
        .no-touch & {
          display: none;
        }
      }

      .close-contact-form {
        outline: none;
        font-size: rem-calc(22);
        background-color: transparent;
        border: none;
        position: absolute;
        top: rem-calc(20);
        right: rem-calc(20);
      }
    }
  }

  &_box {
    color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    overflow: hidden;
    width: calc(50%);

    &--right {
      right: 0;

      &.hidden {
        .section_box_content {
          margin-left: -100%;
          transition: 1s;
        }
      }
    }

    &--left {
      left: 0;

      &.hidden {
        .section_box_content {
          margin-left: 100%;
          transition: 1s;
        }
      }
    }

    &--white {
      .section_box_content {
        background-color: rgba(255, 255, 255, 0.73);
        color: #000;
      }
    }

    &_content {
      background-color: rgba(0, 0, 0, 0.73);
      width: 100%;
      position: relative;
      height: 100vh;
      transition: 1s;
    }
  }
}