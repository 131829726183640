.diamond {
  background-color: #fff;
  height: rem-calc(10);
  float: right;
  width: rem-calc(10);
  transform: rotate(45deg);
  position: relative;
  left: rem-calc(-2);
  transition: .3s;
}

.nav {
  display: none;

  @media(min-width : $breakpoint) {
    .no-touch & {
      display: block;
    }
  }
  
  position: fixed;
  top: calc(50vh - 126px);
  right: rem-calc(20);
  z-index: 1000;

  &_text {
    background-color: $orange;
    color: #fff;
    float: left;
    margin-right: rem-calc(7);
    margin-top: rem-calc(-9);
    padding: rem-calc(9);
    font-size: rem-calc(12);
    position: absolute;
    right: rem-calc(17);
    white-space: nowrap;
    transform: scale(0);
    transition: .3s;

    &:before {
      content: '';
      background-color: $orange;
      height: 1px;
      position: absolute;
      top: calc(50% - 1px);
      width: 10px;
      right: -9px;
    }
  }

  &_item {
    height: rem-calc(46);
    position: relative;

    &:hover {
      .diamond {
        background-color: $orange;
        transform: scale(1.3) rotate(45deg);
        transition: .3s;
      }

      .nav_text {
        transform: scale(1);
        transition: .3s;
      }
    }

    &:before {
      background-color: #fff;
      content: '';
      height: 100%;
      position: absolute;
      right: 7px;
      top: 0;
      width: 1px;
    }

    &:last-child {
      &:before {
        display: none;
      }
    }

    &--active {
      &:after {
        background-color: $orange;
      }

      .diamond {
        background-color: $orange;
      }
    }
  }
}