.btn {
  outline: none;
  font-family: $font2;
  display: inline-block;
  padding: rem-calc(18) rem-calc(46);
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 2px;

  &--primary {
    color: #fff;
    background-color: $orange;
  }

  &--contact-form {
    position: absolute;
    z-index: 100;
    top: 0;
    left: calc(75% - 98px);
    display: none;

    @media(min-width : $breakpoint) {
      .no-touch & {
        display: block;
      }
    }
  }

  &--arrow {
    border-radius: 0;
    position: absolute;
    top: 0;
    left: 50%;
    height: 50px;
    padding: 0;
    width: 50px;
    z-index: 100;

    &:hover {
      background-color: #fff;
    }

    &.prev {
      left: calc(50% - 50px);
      transform: rotate(180deg);

      @media(min-width : $breakpoint) {
        .no-touch & {
          left: calc(100% - 50px);
        }
      }
    }

    &.next {
      @media(min-width : $breakpoint) {
        .no-touch & {
          left: 100%;
        }
      }
    }
  }
}

.close-contact-form {
  display: none;

  @media(min-width : $breakpoint) {
    .no-touch & {
      display: block;
    }
  }
}
