$font: 'Muli', sans-serif;
$font2: 'Muli', sans-serif;

$small-text: rem-calc(12);
$text: rem-calc(18);
$medium-text: rem-calc(24);
$medium-up-text: rem-calc(28);
$big-text: 4.5vh;

$orange: #fa6900;
$black: #000;