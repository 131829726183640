@import "../../../../node_modules/slick-carousel/slick/slick.scss";

.slider-img {
  transition: 1s;
  width: 100%;

  @media(max-width : $breakpoint - 1) {
    transform: translate3d(0, 0, 0) !important;
    top: 0 !important;
  }

  @media(min-width : $breakpoint) {
    .no-touch & {
      width: 50vw;
      z-index: 100;
      transform: translate3d(0, 100vh, 0);
    }
  }
}

.slider-content {
  transition: 1s;
  position: relative;

  @media(max-width : $breakpoint - 1) {
    transform: translate3d(0, 0, 0) !important;
    top: 0 !important;
  }

  @media(min-width : $breakpoint) {
    .no-touch & {
      height: 100vh;
      width: 50vw;
    }
  }
}