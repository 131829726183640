.panel {
  overflow: hidden;
  z-index: 100;

  @media (max-width : $breakpoint - 1) {
    width: 100% !important;
    transform: translate3d(0, 0, 0) !important;
  }

  .no-touch & {
    @media (min-width : $breakpoint) {
      bottom: 0;
      height: 95vh;
      width: 45vw;
      position: fixed;
      top: 2.5vh;
      transition: 1s;
    }
  }

  .h2, .h3, .text, .text-up, .form, .fp-controlArrow {

    @media (max-width : $breakpoint - 1) {
      transform: translateY(0) translateZ(0) !important;
      opacity: 1 !important;
    }

    margin-bottom: rem-calc(40);
    transition: all .5s;

    .no-touch & {
      transform: translateY(100px) translateZ(0);
      opacity: 0;
    }
  }

  .h3 {
    margin-bottom: 70px;
  }

  &.active {
    .h2, .h3, .text, .text-up, .fp-controlArrow  {
      transform: translateY(0px) translateZ(0);
      opacity: 1;
      transition: all .5s;
    }

    .h2 {
      transition-delay: .10s;
    }

    .h3 {
      transition-delay: .15s;
    }

    .text {
      transition-delay: .20s;
    }

    .text-up {
      transition-delay: .30s;
    }
  }

  &_content {
    max-width: 80%;
    transition: 1s;

    .no-touch & {
      @media (min-width : $breakpoint) {
        padding: 60px;
        position: absolute;
        left: 10%;
        bottom: 2vw;
      }
    }
  }

  &_box {
    width: 100%;
    position: relative;
    transition: 1s;
    padding: 40px 0px;
    box-sizing: border-box;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate3d(0, 0, 0);

    @media (max-width : $breakpoint - 1) {
      transform: translate3d(0px, 0px, 0px) !important;
      left: 0 !important;
      top: 0 !important;
    }

    .no-touch & {
      @media (min-width: $breakpoint) {
        height: 100vh;
        padding: 40px;
      }
    }
  }

  &--right {
    right: 5vw;

    .up-to-down {
      top: 0;
      transition: 1s;
    }

    &.first {
      .panel_box {
        left: 0;
      }
    }
  }

  &--left {
    left: 5vw;

    &.active {
      .panel_box {
        left: 0;
      }
    }

    .panel_box {
      top: 0;
      transition: 1s;

      .no-touch & {
        transform: translate3d(100%, 0, 0);
        //left: 100%;
      }
    }
  }

  &--black {
    .panel_box {
      background-color: rgba(0, 0, 0, 0.73);
      color: #fff;
    }
  }

  &--black-op {
    .panel_box {
      background-color: #01060c;
      color: #fff;
    }
  }

  &--white {
    .panel_box {
      background-color: #fff;
      color: #000;

      @media (max-width : $breakpoint - 1) {
        background-color: #fff;
      }

      @media (min-width : $breakpoint) {
        .no-touch & {
          background-color: rgba(255, 255, 255, 0.73);
        }
      }

    }
  }
}

.fp-completely {
  .panel {
    .h2, .h3, .text, .text-up, .fp-controlArrow  {
      transform: translateY(0px) translateZ(0);
      opacity: 1;
      transition: all .5s;
    }

    .h2 {
      transition-delay: .10s;
    }

    .h3 {
      transition-delay: .15s;
    }

    .text {
      transition-delay: .20s;
    }

    .text-up {
      transition-delay: .30s;
    }
  }
}