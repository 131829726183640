//Tile
.tile {
  font-size: $big-text;
  //overflow: hidden;
  text-align: center;

  &_img {
    @media (max-width : $breakpoint - 1) {
      height: 50vh !important;
    }
  }

  &:nth-child(2) {
    color: $orange;
  }

  &_wrap {
    transform: translateY(0);
    @include cubic-transition;
  }

  @media (min-width : $breakpoint) {
    &:hover {
      .tile_wrap {
        .no-touch & {
          transform: translateY(rem-calc(-140));
        }
      }

      .tile_text {
        transform: translateY(0);
        @include cubic-transition;
      }
    }
  }

  &_img {
    background-size: cover;
    text-align: center;
  }

  &_text {
    position: relative;
    color: #fff;
    padding: rem-calc(40);
    @include cubic-transition;

    @media (min-width : $breakpoint) {
      .no-touch & {
        transform: translateY(rem-calc(40));
      }
    }
  }
}
//.Tile