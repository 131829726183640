.form-group {
  label {
    display: block;
    width: 100%;
    @extend .space-tiny;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.textarea {
  min-height: rem-calc(160);
}