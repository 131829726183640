.al-center {
  text-align: center;
}

.backdrop {
  &:before {
    background-color: black;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
  }
}

.super-index {
  z-index: 2000 !important;
}

.current-index {
  z-index: 100 !important;
}

.navigation .fp-completely {
  z-index: 2000 !important;

  &.section--main {
    .section_img {
      opacity: 1 !important;
    }

    .panel--right.first .panel_box {
      left: 0 !important;
    }

    .panel {
      .h2, .h3, .text {
        opacity: 1;
        transform: translateY(0) translateZ(0);
      }
    }
  }

  &.section--approach {
    .panel--left .panel_box {
      top: 0 !important;
      left: 0 !important;

      .h2, .h3, .text {
        opacity: 1;
      }
    }

    .section_img {
      top: 0 !important;
    }
  }
  
  &.section--gallery {
    .panel_box--up {
      top: 0 !important;
    }

    .panel {
      .h2, .h3, .text {
        opacity: 1;
        transform: translateY(0) translateZ(0);
      }
    }

    .section_img {
      top: 0 !important;
    }
  }

  &.section--team {
    .section_img {
      opacity: 1 !important;
      top: 0 !important;
    }

    .panel_box {
      top: 0 !important;
    }
  }

  &.section--service {
    opacity: 1 !important;
  }

  &.section--contact {
    opacity: 1 !important;
  }
}