@mixin bounce-animation($size, $type) {
  $distance-short: 0;
  $distance-long: 0;
  $i: 0;
  $time: 0;

  @if($size == "small") {
    $distance-short: 10px;
    $distance-long: -100px;
    $time: .5s;
  } @else {
    $distance-short: 50px;
    $distance-long: -100vh;
    $time: .5s;
  }

  animation: bounce-animation--#{$size}--#{$type}-frames linear $time;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;

  @keyframes bounce-animation--#{$size}--#{$type}-frames {
    @if($type == "out") {
      0% {
          transform:  translate(0px, 0px);
      }
      30% {
        transform:  translate(0px, $distance-short);
      }
      100% {
        transform:  translate(0px, $distance-long);
      }
    } @else {
      0% {
        transform:  translate(0px, $distance-long);
      }
      70% {
        transform:  translate(0px, $distance-short);
      }
      100% {
        transform:  translate(0px, 0px);
      }
    }
  }
}

@mixin generate-bounce-animation($size, $type) {
  .bounce-animation--#{$size}--#{$type} {
    @include bounce-animation($size, $type);
  }
}

@include generate-bounce-animation("small", "out");
@include generate-bounce-animation("small", "in");
@include generate-bounce-animation("big", "out");
@include generate-bounce-animation("big", "in");

//.bounce-animation