// Variables
@import "variables";

// Vendors
@import './vendors/normalize';
@import './vendors/bootstrap';
@import "../../../node_modules/fullpage.js/dist/jquery.fullpage";
@import './vendors/slick-slider';
@import './vendors/panelScroller';

// Base
@import "./global/mixins";
@import "./base/settings";
@import "./base/fonts";

// Global
@import "./global/layout";
@import "./global/typography";
@import "./global/forms";
@import "./global/buttons";

// Components
@import "./components/sections";
@import "./components/tile";
@import "./components/helpers";
@import "./components/panels";
@import "./components/animations";
@import "./components/nav";

// Settings
@charset 'utf-8';

body {
  background-color: black;
}

.logo {
  position: absolute;
  z-index: 1000;
  width: 90px;
  top: 60px;
  left: calc(5vw + 40px);

  @media (min-width : $breakpoint) {
    .no-touch & {
      position: fixed;
    }
  }

  svg {
    width: 120px;
  }

  path, .cls-1 {
    fill: black;
  }

  @media (max-width : $breakpoint - 1) {
    display: none;
  }
}


.fp-viewing-2 {
  path, .cls-1 {
    fill: #fff;
  }
}

//Contact
.contact-item {
  &--offset {
    margin-left: 48px;
  }

  a {
    color: $black;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    p {
      margin-right: rem-calc(22);
      margin-bottom: 0;
    }
  }

  p {
    margin-bottom: 0;
    @extend .text;
  }

  .icon {
    margin-right: rem-calc(22);
    display: block;
    font-size: rem-calc(25);
    float: left;
    height: 100%;
    position: relative;
    top: 5px;
  }
}
//.Contact

h1 {
  font-size: 2em;
  margin: 0;
}

.fp-controlArrow {
  color: black;
  background-color: rgb(232, 231, 234);
  height: 40px;
  width: 40px !important;
  border: none !important;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover {
    background-color: $orange;

    color: #fff;
  }

  &:before {
    position: absolute;
    top: 11px;
    left: 12px;
  }

  &.fp-next {
    right: -10px;

    .no-touch & {
      @media(min-width : $breakpoint) {
        right: -45px;
      }

      @media(min-width : $wide-breakpoint) {
        //right: -75px;
      }
    }

    @extend .icon-arrow-right-7-01;
  }

  &.fp-prev {
    left: -10px;

    .no-touch & {
      @media(min-width : $breakpoint) {
        left: -45px;
      }

      @media(min-width : $wide-breakpoint) {
        //left: -75px;
      }
    }

    @extend .icon-arrow-right-7-01;
    transform: rotate(180deg) !important;
  }
}

/* -------------- loader10 -------------- */
.box{
  background: black;
  position: absolute;
  z-index: 4000;
  height: 100vh;
  width: 100vw;
  transition: all .2s ease;
}

.loader10:before{
  content: "";
  position: absolute;
  top: 0px;
  left: -25px;
  height: 12px;
  width: 12px;
  border-radius: 12px;
  -webkit-animation: loader10g 3s ease-in-out infinite;
  animation: loader10g 3s ease-in-out infinite;
}

.loader10{
  position: relative;
  width: 12px;
  height: 12px;
  top: 46%;
  left: 46%;
  border-radius: 12px;
  -webkit-animation: loader10m 3s ease-in-out infinite;
  animation: loader10m 3s ease-in-out infinite;
}

.loader10:after{
  content: "";
  position: absolute;
  top: 0px;
  left: 25px;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  -webkit-animation: loader10d 3s ease-in-out infinite;
  animation: loader10d 3s ease-in-out infinite;
}

@-webkit-keyframes loader10g{
  0%{background-color: rgba(255, 255, 255, .2);}
  25%{background-color: rgba(255, 255, 255, 1);}
  50%{background-color: rgba(255, 255, 255, .2);}
  75%{background-color: rgba(255, 255, 255, .2);}
  100%{background-color: rgba(255, 255, 255, .2);}
}
@keyframes loader10g{
  0%{background-color: rgba(255, 255, 255, .2);}
  25%{background-color: rgba(255, 255, 255, 1);}
  50%{background-color: rgba(255, 255, 255, .2);}
  75%{background-color: rgba(255, 255, 255, .2);}
  100%{background-color: rgba(255, 255, 255, .2);}
}

@-webkit-keyframes loader10m{
  0%{background-color: rgba(255, 255, 255, .2);}
  25%{background-color: rgba(255, 255, 255, .2);}
  50%{background-color: rgba(255, 255, 255, 1);}
  75%{background-color: rgba(255, 255, 255, .2);}
  100%{background-color: rgba(255, 255, 255, .2);}
}
@keyframes loader10m{
  0%{background-color: rgba(255, 255, 255, .2);}
  25%{background-color: rgba(255, 255, 255, .2);}
  50%{background-color: rgba(255, 255, 255, 1);}
  75%{background-color: rgba(255, 255, 255, .2);}
  100%{background-color: rgba(255, 255, 255, .2);}
}

@-webkit-keyframes loader10d{
  0%{background-color: rgba(255, 255, 255, .2);}
  25%{background-color: rgba(255, 255, 255, .2);}
  50%{background-color: rgba(255, 255, 255, .2);}
  75%{background-color: rgba(255, 255, 255, 1);}
  100%{background-color: rgba(255, 255, 255, .2);}
}

@keyframes loader10d{
  0%{background-color: rgba(255, 255, 255, .2);}
  25%{background-color: rgba(255, 255, 255, .2);}
  50%{background-color: rgba(255, 255, 255, .2);}
  75%{background-color: rgba(255, 255, 255, 1);}
  100%{background-color: rgba(255, 255, 255, .2);}
}

.form-message {
  float: right;
  margin-top: 30px;
}