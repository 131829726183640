.pn-scroll {
  background-color: black;
  height: 100%;
  position: relative;
  width: 100%;

  &-wrapper {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media(max-width : 1023px) {
      display: none;
    }
  }
}